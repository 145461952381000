<template>
    <modal width="750px" top="40px" v-show="visible" @close="closeModal">
        <span slot="header"> Period för kostnaden</span>
        <span slot="body">
            <div class="flex space-x-4 w-full text-sm">
                <p class="p-2">Fakturadatum: {{ accrualsForData.invoiceDate }}</p>
                <p class="p-2">Startdatum: {{ accrualsForData.startDate }}</p>
                <p class="p-2">Slutdatum: {{ accrualsForData.stopDate }}</p>
                <p class="p-2">Belopp: {{ accrualsForData.amount | swedishNumberFormat }}</p>
            </div>
            <table class="w-full border text-sm">
                <tr>
                    <th class="py-2 px-3">Månad</th>
                    <th class="py-2 px-3 text-right" align="right">Belopp</th>
                    <th class="py-2 px-3 text-right" align="right">Konto</th>
                </tr>
                <tr v-for="item in data" :key="item.id" class="">
                    <td class="py-2 px-3">{{ item.year }} - {{ item.monthName }}</td>
                    <td class="py-2 px-3 text-right" align="right">{{ item.amount | swedishNumberFormat }}</td>
                    <td class="py-2 px-3 text-right" align="right">{{ item.account }}</td>
                </tr>
            </table>
        </span>
    </modal>
</template>
<script>
export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },

        data: {
            type: Array,
        },

        accrualsForData: {
            type: Object,
        },
    },

    components: {
        modal: () => import(/* webpackChunkName: "Modal" */ "../../../components/modal"),
    },

    methods: {
        closeModal() {
            this.$emit("close");
        },
    },
};
</script>
<style scoped>
td {
    border: 1px solid #ddd;
}
</style>
